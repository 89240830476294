import { AxiosResponse } from "axios";

import { logger as baseLogger } from "@core/logger";
import { Network } from "@core/models";

import useAuthStore from "../stores/auth.store";
import axiosClient from "./apiClient";

const logger = baseLogger.getSubLogger({ name: "network.api" });

export class MdslApi {
  async getAuthToken(): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useAuthStore.getState().getToken!();
  }
  get axiosDefaults() {
    return this.getAuthToken().then((token) => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  }

  async registerUser(): Promise<AxiosResponse> {
    return axiosClient
      .post(
        "/users",
        {},
        {
          ...(await this.axiosDefaults),
          params: {
            max_management_level: 4,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => {
        //if 409, user already exists
        if (err.response.status === 409) {
          return;
        }
      });
  }

  async getNetworks(): Promise<Network[]> {
    // /networks
    const fetchNetworks = await axiosClient.get("/networks", await this.axiosDefaults);
    // if networks 401, we might not be authed on MDSL,
    // so we call registerUsers
    if (fetchNetworks.status === 401) {
      logger.error("Unauthorized /networks");
      logger.trace("Registering user to MDSL");
      const res = await this.registerUser();
      if (res) {
        logger.trace("User registered", res);
        return this.getNetworks();
      }
    }
    logger.trace("getNetworks", fetchNetworks.status);
    return fetchNetworks.data.data.collection;
  }
}
