/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { Theme } from "@mui/system";
import type { AxisLabelsFormatterContextObject, TooltipFormatterContextObject } from "highcharts";

import { HighchartsBase } from "@shared";

import type { Series } from "./ColCompareCard";

type Props = {
  series1: Series;
  series2: Series;
  yUnit: string;
  width?: number;
  categories?: (string | number)[];
  theme?: Theme;
  dense?: boolean;
  scaleYAxis?: boolean;
  loading?: boolean;
};

const formatTooltipValue = (value: number | null, yUnit: string): string => {
  if (value === null) return "N/A";
  return `${value} ${yUnit}`;
};

const isValue = (value: number | null | undefined): boolean => {
  return value !== null && value !== undefined && !isNaN(value);
};

const replaceUndefinedWithNulls = (data: (number | undefined)[]): (number | null)[] => {
  return data.map((value) => value ?? null);
};

const createTooltipFormatter = ({ yUnit, series1, series2 }: Partial<Props>) => {
  return function formatter(this: TooltipFormatterContextObject) {
    let ttip = "";
    if (typeof this?.points === "undefined" || !series1 || !series2) return;
    if (this.points[0] && isValue(this.points[0].y)) {
      ttip += `<span style="color:${this.points[0].color};">&#9679;&nbsp;${series1.label} ${formatTooltipValue(
        this.points[0].y!,
        String(yUnit)
      )} </span><br/>`;
    }
    if (this.points[1] && isValue(this.points[1].y)) {
      ttip += `<span style="color:${this.points[1].color};">&#9679;&nbsp;${series2.label} ${formatTooltipValue(
        this.points[1].y!,
        String(yUnit)
      )} </span><br/>`;
    }
    if (
      this.points[1] &&
      this.points[0] &&
      isValue(this.points[1].y) &&
      isValue(this.points[0].y)
    ) {
      ttip += `<span>&nbsp;&nbsp;&nbsp;Difference ${formatTooltipValue(
        this.points[0].y! - this.points[1].y!,
        String(yUnit)
      )}</span>`;
    }
    return ttip;
  };
};

const createXAxisFormatter = ({ categories }: Partial<Props>) => {
  return function formatter(this: AxisLabelsFormatterContextObject) {
    return categories?.[this.pos];
  };
};

const GraphColCompare: React.FC<Props> = ({
  series1,
  series2,
  yUnit,
  width,
  categories,
  theme,
  dense = false,
  scaleYAxis = false,
  loading = false,
}) => {
  const tooltipFormatter = createTooltipFormatter({ yUnit, series1, series2 });
  const xAxisFormatter = createXAxisFormatter({ categories });

  return (
    <HighchartsBase
      loading={loading}
      yAxisScale={scaleYAxis}
      xAxis={
        categories
          ? {
              gridLineWidth: 0,
              lineWidth: 0,
              categories,
              labels: {
                formatter: xAxisFormatter,
              },
            }
          : undefined
      }
      yUnit={yUnit}
      yAxisOptions={{
        title: {
          text: "",
        },
        gridLineWidth: 0,
        lineWidth: 0,
      }}
      chart={{
        height: dense ? 180 : 240,
        marginTop: 20,
        type: "column",
        zoomType: "",
        width,
        backgroundColor: "white",
      }}
      seriesOptions={{ marker: { enabled: false } }}
      tooltip={{
        shared: true,
        formatter: tooltipFormatter,
      }}
      legend={{ enabled: false }}
      disableBoost
      plotOptions={{
        series: {
          pointWidth: 35,
        },
      }}
      series={[
        {
          ...series1,
          data: replaceUndefinedWithNulls(series1.data),
          type: "column",
          color: theme?.palette.primary.main,
          showInLegend: true,
          pointWidth: 10,
        },
        {
          ...series2,
          data: replaceUndefinedWithNulls(series2.data),
          type: "line",
          showInLegend: true,
          color: theme?.palette.secondary.main,
        },
      ]}
    />
  );
};

export default GraphColCompare;
